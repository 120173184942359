import template from './einnahmen-ausgaben.html';

export {EinnahmenAusgabenComponent, EinnahmenAusgabenController};

const EinnahmenAusgabenComponent = {
  template: template,
  controller: EinnahmenAusgabenController,
  require: {
    parent: '^^finanzkennzahlen'
  }
};

function EinnahmenAusgabenController(finanzkennzahlenService, dateUtilService, formUtilService, scrollService, messagesService, popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  // Determine if the current date is after the first quarter
  const isAfterFirstQuarter = (new Date().getMonth() > 2);

  ctrl.$onInit = function () {
    ctrl.model = finanzkennzahlenService.model.daten;
    ctrl.isBwaAkzeptiert = finanzkennzahlenService.bwaAkzeptiert;
    ctrl.maxAlterJuengsteBilanz = (ctrl.isBwaAkzeptiert && ctrl.model.fkzAuspraegung === 'BWA' && isAfterFirstQuarter) ? 12 : 24; // maxAlter in Monaten -> ist bei der Validierung das minDate
    ctrl.maxAlterAeltereBilanz = ctrl.maxAlterJuengsteBilanz + 12; // darf 12 Monate vor der juengeren Bilanz liegen
    ctrl.model.maxDate1 = new Date();
    ctrl.model.minDate1 = dateUtilService.tagVorXMonaten(new Date(), ctrl.maxAlterJuengsteBilanz);
    ctrl.model.maxDate2 = dateUtilService.tagVorXJahre(new Date(), 1);
    ctrl.model.minDate2 = dateUtilService.tagVorXMonaten(new Date(), ctrl.maxAlterAeltereBilanz);
    ctrl.messagesService = messagesService;
    ctrl.onChangeFinanzkennzahlenArt = onChangeFinanzkennzahlenArt;
    ctrl.onChangeFkzAuspraegung = onChangeFkzAuspraegung;
    ctrl.onChangeBilanzstichtag1 = onChangeBilanzstichtag1;
    ctrl.onChangeBilanzstichtag2 = onChangeBilanzstichtag2;
    ctrl.removeOnPaste = removeOnPaste;
    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;
    ctrl.isEmpty = isEmpty;
    ctrl.parent.addValidator(validate, 2);

    // PopUps
    ctrl.popupFuerBwaInfo = popupFuerBwaInfo;
    ctrl.popupFuerSteuerberater = popupFuerSteuerberater;
    ctrl.popupFuerUploadInfo = popupFuerUploadInfo;

    // - Info-Glühbirnen
    ctrl.popupFuerBilanzstichtagInfo = popupFuerBilanzstichtagInfo;
    ctrl.popupFuerBetriebseinnahmenInfo = popupFuerBetriebseinnahmenInfo;
    ctrl.popupFuerGesamtabschreibung = popupFuerGesamtabschreibung;
    ctrl.popupFuerZinsaufwand = popupFuerZinsaufwand;
    ctrl.popupFuerJahresueberschuss = popupFuerJahresueberschuss;

    ctrl.showFinanzkennzahlenEingabe = showFinanzkennzahlenEingabe;
  };

  function removeOnPaste(event, fieldname) {
    formUtilService.removeOnPaste(event, ctrl.einnahmenAusgabenForm, fieldname);
  }

  /**
   * Handler für Auswahl einer Finanzkennzahlen-Art (GuV oder EÜR)
   */
  function onChangeFinanzkennzahlenArt() {
    finanzkennzahlenService.model.submitted = false;
  }

  /**
   * Handler für Auswahl einer Finanzkennzahlen-Auspraegung (Jahresabschluss oder BWA)
   */
  function onChangeFkzAuspraegung() {
    const isBWA = ctrl.isBwaAkzeptiert && ctrl.model.fkzAuspraegung === 'BWA';

    // Update maxAlter and Bilanzstichtage after FkzAuspraegung changes
    if (isBWA && isAfterFirstQuarter) {
      ctrl.maxAlterJuengsteBilanz = 12;
      ctrl.model.bilanzstichtag1 = finanzkennzahlenService.calculateBilanzstichtage(0);
      ctrl.model.bilanzstichtag2 = finanzkennzahlenService.calculateBilanzstichtage(1);
    } else {
      ctrl.maxAlterJuengsteBilanz = 24;
      ctrl.model.bilanzstichtag1 = finanzkennzahlenService.calculateBilanzstichtage(1);
      ctrl.model.bilanzstichtag2 = finanzkennzahlenService.calculateBilanzstichtage(2);
    }
    ctrl.maxAlterAeltereBilanz = ctrl.maxAlterJuengsteBilanz + 12;

    // Recalculate dates
    ctrl.model.minDate1 = dateUtilService.tagVorXMonaten(new Date(), ctrl.maxAlterJuengsteBilanz);
    ctrl.model.minDate2 = dateUtilService.tagVorXMonaten(new Date(), ctrl.maxAlterAeltereBilanz);

    finanzkennzahlenService.model.submitted = false;
  }

  /**
   * Handler für Änderungen an den Bilanzstichtagen
   */
  function onChangeBilanzstichtag1() {
    ctrl.model.bilanzstichtag2 = (ctrl.model.bilanzstichtag1) ?
      dateUtilService.tagVorXJahre(new Date(ctrl.model.bilanzstichtag1), 1) : null;
  }

  function onChangeBilanzstichtag2() {
    ctrl.model.bilanzstichtag1 = (ctrl.model.bilanzstichtag2) ?
      dateUtilService.getTagInXJahren(new Date(ctrl.model.bilanzstichtag2), 1) : null;
  }

  /**
   * Prüft den Validierungsstatus der Komponente
   */
  function validate() {
    finanzkennzahlenService.model.invalidFields.einnahmenAusgaben = [];
    if (isFormError()) {
      finanzkennzahlenService.model.invalidFields.einnahmenAusgaben =
        formUtilService.getErroneousFields(ctrl.einnahmenAusgabenForm);
      scrollService.scrollTo('finanzkennzahlen');
      return false;
    }
    return true;
  }

  /**
   * Liefert den Validierungsstatus eines Formularfelds zur Anzeige von Feld-spezifischen Fehlern.
   */
  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.einnahmenAusgabenForm, finanzkennzahlenService.model.submitted, fieldname);
  }

  /**
   * Liefert den Validierungsstatus einer zusammengehörigen Gruppe von Formularfeldern zur Anzeige
   * einer Gruppen-Fehlermeldung.
   */
  function isFormError() {
    return formUtilService.isFormError(ctrl.einnahmenAusgabenForm);
  }

  /**
   * Liefert den Validierungsstatus einer zusammengehörigen Gruppe von Formularfeldern zur Anzeige
   * einer Gruppen-Fehlermeldung (nur nach Submit des Formulars).
   */
  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.einnahmenAusgabenForm, finanzkennzahlenService.model.submitted);
  }

/**
 * Öffnen die Popups
 */

  function popupFuerBwaInfo() {
    popupService.openAlert('bwaInfo', 'Prozessschritt 3 - ');
  }

  function popupFuerSteuerberater() {
    popupService.openAlert('steuerberater', 'Prozessschritt 3 - ');
  }

  function popupFuerUploadInfo() {
    popupService.openAlert(finanzkennzahlenService.bwaAkzeptiert ? 'uploadInfoBWA' : 'uploadInfo', 'Prozessschritt 3 - ');
  }


  /**
   * Öffnet die Info-Glühbirnen Popups
   */
  function popupFuerBilanzstichtagInfo() {
    popupService.openAlert('bilanzstichtagInfo', 'Prozessschritt 3 - ');
  }

  function popupFuerBetriebseinnahmenInfo() {
    popupService.openAlert('betriebseinnahmen', 'Prozessschritt 3 - ');
  }

  function popupFuerGesamtabschreibung() {
    popupService.openAlert('gesamtabschreibung', 'Prozessschritt 3 - ');
  }

  function popupFuerZinsaufwand() {
    popupService.openAlert('zinsaufwand', 'Prozessschritt 3 - ');
  }

  function popupFuerJahresueberschuss() {
    popupService.openAlert('jahresueberschuss', 'Prozessschritt 3 - ');
  }

  function isEmpty(value) {
    return !value;
  }

  function showFinanzkennzahlenEingabe() {
    if (ctrl.isBwaAkzeptiert) {
      return ctrl.model.finanzkennzahlenArt != null && ctrl.model.fkzAuspraegung != null;
    }else {
      ctrl.model.fkzAuspraegung = 'JAHRESABSCHLUSS';
      return ctrl.model.finanzkennzahlenArt != null;
    }
  }

}

